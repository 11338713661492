<template>
  <v-container>
    <v-progress-circular
      indeterminate
      color="primary"
      v-if="!this.apiErrorMsg"
    ></v-progress-circular>

    <br />

    <v-alert v-if="this.apiErrorMsg" prominent type="error">
      <v-row align="center">
        <v-col class="grow">
          {{ this.apiErrorMsg }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="closeWindow()">Close Window</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <span v-if="!this.apiErrorMsg">
      <b>
        Please do not close this window, executing job... <br />
        This windows will auto close after completed.
      </b>
    </span>
  </v-container>
</template>

<script>
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";
import { mapState } from "vuex";
import Api from "@/objects/api";
import moment from "moment";

export default {
  props: ["params"],
  mixins: [hrdcFunction],
  data: () => ({
    apiXeroExchangeCode: new Api(),
    apiStoreXeroToken: new Api(),
    hrdcData: new HrdcData(),
    apiErrorMsg: null,
  }),
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      hrdc_einvoicing: (state) => state.hrdc_einvoicing.data,
    }),
  },
  methods: {
    getRedirectUri() {
      return `${process.env.VUE_APP_URL}/en/service/hrdc_einvoicing/callback_xero_einvoicing`;
    },
    closeWindow() {
      window.close();
    },
  },
  created() {
    const apiDetails = {
      apiStoreXeroToken: {
        url: `${this.$service["hrdc_einvoicing"]}/v1/en/console/xero/store-xero-token`,
        method: "post",
      },
      apiXeroExchangeCode: {
        url: `${this.$service["hrdc_einvoicing"]}/v1/en/console/xero/exchange-code`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.apiXeroExchangeCode.setParams({
      xero_exchange_code_url: process.env.VUE_APP_XERO_EXCHANGE_CODE_URL,
      grant_type: "authorization_code",
      code: this.$route.query.code,
      redirect_uri: this.getRedirectUri(),
      basic_auth_token: this.getXeroBasicAuthorizationCode(),
    });
    this.apiXeroExchangeCode.setCallbackCompleted((response) => {
      try {
        if (!response || !response.status) {
          throw new Error("Invalid Response");
        }

        // 1. Store refresh_token in db.
        this.apiStoreXeroToken.setParams({
          valid_from: moment().format("YYYY-MM-DD"),
          valid_to: moment().add(58, "days").format("YYYY-MM-DD"),
          token_type: "refresh_token",
          token: response.data.refresh_token,
          userUuid: this.auth.uuid,
          applicationUuid: this.$route.query.state,
          subscriptionStageId:
            this.hrdcData.stagePriority.subscriptionDateScheduled,
        });
        this.apiStoreXeroToken.setCallbackCompleted(
          async (apiStoreXeroTokenRes) => {
            try {
              if (!apiStoreXeroTokenRes || !apiStoreXeroTokenRes.status) {
                throw new Error("Failed to generate refresh token");
              }

              return window.close();
            } catch (err) {
              console.log(err);
              this.apiErrorMsg = err.message;
              return false;
            }
          }
        );

        this.apiStoreXeroToken.fetch();
      } catch (err) {
        let errorMsg = err.message;
        if (err.response?.data?.error) {
          errorMsg = err.response.data.error;
        }

        this.apiErrorMsg = `
          XERO Error Message: ${errorMsg};
          You can close this windows manually now.
        `;

        return false;
      }
    });
    this.apiXeroExchangeCode.fetch();
  },
};
</script>
